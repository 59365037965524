<template>
    <div class="client-sales">
        <div class="new-sale" v-if="new_sale">
            <div class="new-sale-dialog">
                <div class="exit-new-sale" @click="new_sale =!new_sale, selected_sale = null">
                    <i class="material-icons">highlight_off</i>
                </div>
                <NewSale :merakez="selected_merakez" @close="added_sale" :is_duplicate="is_duplicate" :sale="selected_sale"/>
            </div>
        </div>
        <div class="assortments">
            <div class="search-merakez">
                <el-input placeholder="חיפוש" v-model="search_string" :input-style="input_options"/>
            </div>
            <template v-for="merakez in filtered_assortments" :key="merakez.makat">
                <div class="merakez-line" @click="selected_merakez = merakez">
                    <p>{{merakez.makat}}</p>
                    <p style="width:80%; text-align:start;">{{merakez.name}}</p>
                </div>
            </template>
        </div>
        <div class="sales-main">
            <div class="no-sales" v-if="!selected_merakez">
                <img :src="arrow" alt="">
                <p>אנא בחר לקוח מרכז</p>
            </div>
            <div class="options-bar" v-else>
                  <el-button type="success" style="margin-left: 10px;" @click="new_sale =!new_sale">מבצע חדש</el-button>
                  <el-button type="primary" style="margin-left: 10px;" v-if="!hide_past_sales" @click="hide_past_sales = !hide_past_sales">הסתר מבצעי עבר</el-button>
                  <el-button type="primary" style="margin-left: 10px;" v-if="hide_past_sales" @click="hide_past_sales = !hide_past_sales">הצג מבצעי עבר</el-button>
                  <el-button type="success" style="margin-left: 10px;" @click="handle_export_to_scv" plain>ייצא לאקסל</el-button>
            </div>
            <div class="no-sales" v-if="filtered_sales.length == 0 && selected_merakez">
                <p style="color:var(--yellow); font-size: 24px; text-align:center;">{{selected_merakez.name}}</p>
                <p>אין מבצעים</p>
            </div>
            <template v-for="sale in filtered_sales" :key="sale.uid">
                <div class="sale-box">
                    <div class="sale-image">
                        <img :src="sale.image" alt="">
                    </div>
                    <div class="sale-data">
                        <p style="font-size: 18px; color:var(--yellow);">{{sale.label}}</p>
                        <p>{{sale.description}}</p>
                        <p>ת.התחלה: {{new Date(sale.start_date).toLocaleDateString('he')}}</p>
                        <p>ת.סיום: {{new Date(sale.end_date).toLocaleDateString('he')}}</p>
                    </div>
                    <div class="sale-options">
                        <el-button type="warning" class="sale-option-btn" size="mini" @click="edit_sale(sale)">ערוך</el-button>
                        <el-button type="primary" class="sale-option-btn" size="mini" @click="duplicate_sale(sale)">שכפל</el-button>
                        <el-button type="danger" class="sale-option-btn" size="mini" @click="delete_sale(sale)">מחק</el-button>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, onMounted, watch } from '@vue/runtime-core';
import { projectFirestore } from '../../firebase/config';
import NewSale from '../../components/Admin/Sales/NewSale.vue';
import Swal from 'sweetalert2';
import { slide_pop_successs } from '../../Methods/Msgs';
import XLSX from "xlsx";

export default {
components:{NewSale},
setup(){
    const is_duplicate = ref(false)
    const new_sale = ref(false);
    const selected_sale = ref(null);
    
    const assortments = ref([]);
    const search_string = ref('');
    const selected_merakez = ref(null);

    const sales = ref([]);

    const hide_past_sales = ref(false);

    const filtered_sales = ref(computed(() => {
        if(!hide_past_sales.value){
            return sales.value
        }else{
            return sales.value.filter(sale => {
                return new Date() < new Date(sale.end_date)
            })
        }
        
    }))

    const filtered_assortments = ref(computed(() => {
        if(!search_string.value){
            return assortments.value
        }else{
            return assortments.value.filter(ass => {
                return ass.name.includes(search_string.value) || ass.makat.includes(search_string.value)
            })
        }
    }))

    const get_assortments = async () => {
        const docs = await projectFirestore.collection('Assortments').get()
        if(!docs.empty){
            assortments.value = docs.docs.map(doc => doc.data());
        }
    }

    const input_options = {
        'background': 'var(--main)',
        'color': 'white'
    }

    const arrow = ref(computed(() => {
        if(window.innerWidth <= 950){
            return 'https://image.flaticon.com/icons/png/512/1635/1635652.png'
        }else{
            return 'https://image.flaticon.com/icons/png/512/1635/1635581.png'
        }
    }))

    const added_sale = (e) => {
        let inx = sales.value.findIndex(sale => {
            return sale.uid == e.uid
        })
        if( inx == -1){
            sales.value.push(e);
        }
        else{
            sales.value[inx] = e
        }
        new_sale.value = false;
        selected_sale.value = null
        is_duplicate.value = false
    }

    const get_sales = async () => {
        sales.value = [];
        const docs = await projectFirestore.collection('Sales').where('merakez', '==', selected_merakez.value.makat).get()
        if(!docs.empty){
            sales.value = docs.docs.map(doc => doc.data());
            sales.value.forEach(async sale => {
                sale.image = (await projectFirestore.collection('Products').doc(sale.makats_arr[0]).get()).data().image
            })
        }
    }

    const edit_sale = (sale) => {
        selected_sale.value = sale;
        new_sale.value = true;
    }

    const duplicate_sale = (sale) =>{
        selected_sale.value = sale;
        new_sale.value = true;
        is_duplicate.value = true
    }

    const delete_sale = (sale) => {
        Swal.fire({
            icon: 'question',
            title: 'מחיקת מבצע',
            text: 'האם אתה בטוח? המבצע יימחק לצמיתות!',
            confirmButtonText: 'בטוח!',
            cancelButtonText: 'ביטול',
            showCancelButton: true
        }).then(async res => {
            if(res.isConfirmed){
                await projectFirestore.collection('Sales').doc(sale.uid).delete();
                slide_pop_successs("המבצע נמחק בהצלחה!")
                let inx = sales.value.findIndex(_sale => {
                    return _sale.uid == sale.uid
                })
                sales.value.splice(inx, 1);
            }
        })
    }

    const handle_export_to_scv = ()=>{
        let rows = JSON.parse(JSON.stringify(filtered_sales.value))

        let workbook = XLSX.utils.book_new();

        let workSheetData =[]

        rows.forEach((row,index)=>{
            if(index>0){
                workSheetData.push([])
            }
            workSheetData.push(['סוג המבצע','תאריך התחלה','תאריך סיום','מאושר','תמונה','מס מרכז','תיאור'])
            workSheetData.push([
                row.label,
                new Date(row.date_start_date.seconds *1000).toLocaleDateString('he'),
                new Date(row.date_end_date.seconds *1000).toLocaleDateString('he'),
                row.approved?'מאושר':'לא מאושר',
                row.image,
                row.merakez,
                row.description,
            ])
            
            workSheetData.push(['מקט מוצר','שם המוצר','מידה'])
            row.products.forEach(product=>{
                workSheetData.push([
                    product.makat,
                    product.name,
                    product.boxSize
                ])
            })

            if(row.step_1.description){
                workSheetData.push(['תיאור מדרגה 1','מחיר','כמות'])
                workSheetData.push([
                    row.step_1.description,
                    row.step_1.price,
                    row.step_1.amount,
                ])
            }
            if(row.step_2.description){
                workSheetData.push(['תיאור מדרגה 2','מחיר','כמות'])
                workSheetData.push([
                    row.step_2.description,
                    row.step_2.price,
                    row.step_2.amount,
                ])
            }
            if(row.step_3.description){
                workSheetData.push(['תיאור מדרגה 3','מחיר','כמות'])
                workSheetData.push([
                    row.step_3.description,
                    row.step_3.price,
                    row.step_3.amount,
                ])
            }
            if(row.step_4.description){
                workSheetData.push(['תיאור מדרגה 4','מחיר','כמות'])
                workSheetData.push([
                    row.step_4.description,
                    row.step_4.price,
                    row.step_4.amount,
                ])
            }
            if(row.step_5.description){
                workSheetData.push(['תיאור מדרגה 5','מחיר','כמות'])
                workSheetData.push([
                    row.step_5.description,
                    row.step_5.price,
                    row.step_5.amount,
                ])
            }
        })


        const workSheet = XLSX.utils.aoa_to_sheet(workSheetData)
        workbook.Workbook = {};
        workbook.Workbook.Views = [];
        workbook.Workbook.Views[0] = {};
        workbook.Workbook.Views[0].RTL = true;
        XLSX.utils.book_append_sheet(workbook, workSheet, "מבצעים");
        XLSX.writeFile(workbook, "מבצעים.xlsx");
    }

    watch(selected_merakez, () => {
        get_sales();
    })

    onMounted(() => {
        if(!assortments.value || assortments.value.length == 0){
            get_assortments();
        }
    })
    
    return{
        assortments, search_string, input_options, filtered_assortments, selected_merakez, arrow, filtered_sales,
        new_sale, added_sale, edit_sale, selected_sale, delete_sale, hide_past_sales,
        handle_export_to_scv,is_duplicate,duplicate_sale
    }
}
}
</script>

<style scoped>
.client-sales{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 375px calc(100% - 375px);
    grid-template-rows: 100%;
}
.search-merakez{
    position: sticky;
    top: 0;
    width: 100%;
    height: 50px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
}
.assortments{
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.merakez-line{
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    height: 26px;
    cursor: pointer;
    margin-bottom: 5px;
    color: white;
}
.new-sale{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.368);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.new-sale-dialog{
    position: relative;
    width: 500px;
    height: 95%;
    overflow-y: auto;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(128, 128, 128, 0.582); 
    z-index: 2;
}
.exit-new-sale{
    position: absolute;
    top: 5px;
    left: 10px;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--danger);
    z-index: 5;
    background: white;
}
.sales-main{
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.no-sales{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
}
.no-sales img{
    width: 250px;
    height: auto;
}
.options-bar{
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    overflow-x: auto;
    background: var(--main);
}
.sale-box{
    width: 370px;
    height: 100px;
    background: var(--secondary);
    flex-shrink: 0;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.sale-image{
    width: 70px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    overflow: hidden;
}
.sale-data{
    width: 220px;
    height: 100%;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: white;
}
.sale-data p{
    font-size: 14px;
    white-space: nowrap;
}
.sale-options{
    width: 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.sale-option-btn{
    margin: 0;
}
.sale-image img{
    max-width: 100%;
    max-height: 100%;
}
@media only screen and (max-width: 950px) {
    .client-sales{
        grid-template-columns: 100%;
        grid-template-rows: 150px calc(100% - 150px);
    }
    .new-sale-dialog{
        width: 98%;
    }
    .sale-box{
        width: 100%;
    }
}
</style>